import { t } from './translation/Translator';

export enum UnitSystem {
  imperial = 'imperial',
  metric = 'metric',
}

export enum UnitType {
  quantity = 'quantity',
  mass = 'mass',
  volume = 'volume',
  length = 'length',

  // Business Central
  unit = 'unit',
  day = 'day',
  set = 'set',
  m2 = 'm2',
  m3 = 'm3',
  pack = 'pack',
  hour = 'hour',
  vnt = 'vnt',
}

export const isBusinessCentralUnitType = (unitType?: UnitType) => {
  return (
    unitType == UnitType.quantity ||
    unitType == UnitType.unit ||
    unitType == UnitType.day ||
    unitType == UnitType.set ||
    unitType == UnitType.m2 ||
    unitType == UnitType.m3 ||
    unitType == UnitType.pack ||
    unitType == UnitType.hour ||
    unitType == UnitType.vnt
  );
};

export class MetricUnit {
  static giga = 'giga'; // 10^9
  static mega = 'mega'; // 10^6
  static kilo = 'kilo'; // 10^3
  static hecto = 'hecto'; // 10^2
  static deca = 'deca'; // 10^1
  static base = 'base'; // 10^0
  static deci = 'deci'; // 10^-1
  static centi = 'centi'; // 10^-2
  static milli = 'milli'; // 10^-3
  static micro = 'micro'; // 10^-6
  static nano = 'nano'; // 10^-9

  static value(unit?: Unit): number {
    switch (unit) {
      case MetricUnit.giga:
        return 18;
      case MetricUnit.mega:
        return 15;
      case MetricUnit.kilo:
        return 12;
      case MetricUnit.hecto:
        return 11;
      case MetricUnit.deca:
        return 10;
      case MetricUnit.base:
        return 9;
      case MetricUnit.deci:
        return 8;
      case MetricUnit.centi:
        return 7;
      case MetricUnit.milli:
        return 6;
      case MetricUnit.micro:
        return 3;
      case MetricUnit.nano:
        return 0;
      default:
        throw Error('NOT_FOUND');
    }
  }
}

export class ImperialMassUnit {
  static grain = 'grain'; // 1/7000
  static drachm = 'drachm'; // 1/256
  static ounce = 'ounce'; // 1/16
  static pound = 'pound'; // 1
  static stone = 'stone'; // 14
  static quarter = 'quarter'; // 28
  static hundredweight = 'hundredweight'; // 112
  static ton = 'ton'; // 2240
}

export class ImperialLengthUnit {
  static twip = 'twip'; // 1/17280
  static thou = 'thou'; // 1/12000
  static barleycorn = 'barleycorn'; // 1/36
  static inch = 'inch'; // 1/12
  static hand = 'hand'; //  1/3
  static foot = 'foot'; // 1
  static yard = 'yard'; // 3
  static chain = 'chain'; // 66
  static furlong = 'furlong'; // 660
  static mile = 'mile'; // 5280
  static league = 'league'; // 15840
}

export class ImperialVolumeUnit {
  static fluidOunce = 'fluidOunce';
  static gill = 'gill';
  static pint = 'pint';
  static quart = 'quart';
  static gallon = 'gallon';
}

export type Unit = ImperialMassUnit | ImperialLengthUnit | ImperialVolumeUnit | MetricUnit;

export interface UnitOfMeasure {
  system?: UnitSystem;
  unit?: Unit;
  unitType?: UnitType;
}

export const defaultUnitOfMeasure: UnitOfMeasure = {
  system: UnitSystem.metric,
  unit: MetricUnit.base,
  unitType: UnitType.quantity,
};

export function unitTypeToString(type: UnitType) {
  switch (type) {
    case UnitType.quantity:
      return t().quantity.singular.label;
    case UnitType.mass:
      return t().mass.singular.label;
    case UnitType.volume:
      return t().volume.singular.label;
    case UnitType.length:
      return t().length.singular.label;
    case UnitType.unit:
      return t().unit.singular.label;
    case UnitType.day:
      return t().day.plural.label;
    case UnitType.set:
      return t().set.singular.label;
    case UnitType.m2:
      return 'm\u00B2';
    case UnitType.m3:
      return 'm\u00B3';
    case UnitType.pack:
      return t().pack.singular.label;
    case UnitType.hour:
      return t().hour.singular.label;
    case UnitType.vnt:
      return t().vnt.singular.label;
  }
}

export function unitSystemToString(system: UnitSystem) {
  switch (system) {
    case UnitSystem.imperial:
      return 'Imperial';
    case UnitSystem.metric:
      return 'Metric';
  }
}

function metricQuantityString(uom: UnitOfMeasure, quantity: string) {
  const metricUnit = MetricUnit.value(uom.unit);
  const number = Number(parseInt(quantity) / Number(Math.pow(10, metricUnit * 1)));
  return Number(number.toFixed(quantity.length)).toString();
}

export function toQuantityString(quantity: string, uom?: UnitOfMeasure) {
  if (!uom || uom.unitType === UnitType.quantity) return quantity;
  if (uom.system === UnitSystem.metric) {
    return metricQuantityString(uom, quantity);
  } else {
    const imperialUnit = 9;
    const number = Number(parseInt(quantity) / Number(Math.pow(10, imperialUnit * 1)));
    return Number(number.toFixed(quantity.length)).toString();
  }
}

export function getSuffix(uom?: UnitOfMeasure) {
  if (!uom) return '';

  if (uom.system === UnitSystem.metric) {
    switch (uom.unitType) {
      case UnitType.length:
        if (uom.unit === MetricUnit.base) return 'm';
        if (uom.unit === MetricUnit.milli) return 'mm';
        if (uom.unit === MetricUnit.centi) return 'cm';
        if (uom.unit === MetricUnit.kilo) return 'km';
        return '';
      case UnitType.volume:
        if (uom.unit === MetricUnit.base) return 'l';
        if (uom.unit === MetricUnit.milli) return 'ml';
        if (uom.unit === MetricUnit.centi) return 'cl';
        if (uom.unit === MetricUnit.kilo) return 'kl';
        return '';
      case UnitType.mass:
        if (uom.unit === MetricUnit.base) return 'g';
        if (uom.unit === MetricUnit.milli) return 'mg';
        if (uom.unit === MetricUnit.centi) return 'cg';
        if (uom.unit === MetricUnit.kilo) return 'kg';
        if (uom.unit === MetricUnit.mega) return 'tonne';
        return '';
      case UnitType.day:
        return t().day.plural.label;
      case UnitType.m2:
        return 'm\u00B2';
      case UnitType.m3:
        return 'm\u00B3';
      case UnitType.hour:
        return t().hour.plural.label;
      case UnitType.vnt:
        return 'VNT';
      case UnitType.pack:
        return 'PACK';
      case UnitType.set:
        return 'KOMPL';
      case UnitType.unit:
        return 'PCS';
    }
  } else if (uom.system === UnitSystem.imperial) {
    switch (uom.unitType) {
      case UnitType.length:
        if (uom.unit === ImperialLengthUnit.inch) return 'inch';
      default:
        return '';
    }
  }

  return '';
}

export function quantityWithSuffix(quantity: string, uom?: UnitOfMeasure) {
  if (!uom) return quantity;

  return `${quantity} ${getSuffix(uom)}`;
}
