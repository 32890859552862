import React, { useContext, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GetUserResponse, GetUsersResponse, GetUsersVariables, UserQueries } from '../../graphql/user.graphql';
import { UserContext } from '../../context/UserContext';
import {
  CompanyRoleAssignmentQueries,
  GetCompanyRoleAssignmentsForCompanyResponse,
  GetCompanyRoleAssignmentsForCompanyVariables,
  GetCompanyRoleAssignmentsResponse,
  GetCompanyRoleAssignmentsVariables,
} from '../../graphql/companyRoleAssignment.graphql';
import { CompanyRole, CompanyRoleAssignment } from '../../types/companyRoleAssignment';
import { getCurrentCompanyId, storeCurrentCompanyId } from '../../util/token.util';
import {
  CompanyRoleAssignmentContext,
  CompanyRoleAssignmentContextProps,
} from '../../context/CompanyRoleAssignmentContext';
import LoadingScreen from '../Common/LoadingScreen';
import { cloneDeep } from '@apollo/client/utilities';
import { GetBinStatusesResponse, GetBinStatusesVariables, BinStatusQueries } from '../../graphql/binStatus.graphql';
import {
  GetCompanyResponse,
  GetCompanyVariables,
  CompanyQueries,
  GetCompaniesResponse,
  GetCompaniesVariables,
} from '../../graphql/company.graphql';
import {
  GetStockLocationsResponse,
  GetStockLocationsVariables,
  StockLocationQueries,
} from '../../graphql/stockLocation.graphql';
import {
  GetStockLocationRoleAssignmentsResponse,
  GetStockLocationRoleAssignmentsVariables,
  StockLocationRoleAssignmentQueries,
} from '../../graphql/stockLocationRoleAssignment.graphql';
import { BinStatus } from '../../types/binStatus';
import { Company } from '../../types/company';
import { StockLocation } from '../../types/stockLocation';
import { StockLocationRole, StockLocationRoleAssignment } from '../../types/stockLocationRoleAssignment';
import { User } from '../../types/user';
import ContentPage from './ContentPage';
import { CompanyContext, CompanyContextProps } from '../../context/CompanyContext';
import { StockLocationContext, StockLocationContextProps } from '../../context/StockLocationContext';
import {
  StockLocationRoleAssignmentContext,
  StockLocationRoleAssignmentContextProps,
} from '../../context/StockLocationRoleAssignmentContext';
import { BinStatusContext, BinStatusContextProps } from '../../context/BinStatusContext';
import { toMap } from '../../util/map.util';
import OnboardingCompanyPane from '../Onboarding/Panes/OnboardingCompanyPane';
import AcceptAgreementsScreen from '../Authentication/AcceptAgreementsScreen';
import { SuperUserContext, SuperUserContextProps } from '../../context/SuperUserContext';
import { SuperUser } from '../../types/superUser';
import { GetSuperUserResponse, GetSuperUserVariables, SuperUserQueries } from '../../graphql/superUser.graphql';
import { Route, Routes } from 'react-router-dom';
import AdministratorScreen from '../Administrator/AdministratorScreen';
import AdministratorNotAllowedScreen from '../Administrator/AdministratorNotAllowedScreen';
import CompanySelectScreen from './CompanySelectScreen';
import {
  GetTranslationResponse,
  GetTranslationVariables,
  TranslationQueries,
} from '../../graphql/translations/translation.graphql';
import { translationsManager } from '../../types/translation/Translator';
import { Translation } from '../../types/translation/translation';
import { TranslationContext } from '../../context/TranslationContext';
import {
  GetCustomTranslationsResponse,
  GetCustomTranslationsVariables,
  CustomTranslationQueries,
} from '../../graphql/translations/customTranslation.graphql';
import { CustomTranslation } from '../../types/translation/customTranslation';
import { CustomTranslationContext, CustomTranslationContextProps } from '../../context/CustomTranslationContext';

export default function MainPage() {
  const { currentUser, setCurrentUser, companyUsers, setCompanyUsers, signOut } = useContext(UserContext);
  const { translations, setTranslations } = useContext(TranslationContext);

  const [company, setCompany] = useState<Company>(new Company(''));
  const [companies, setCompanies] = useState<Map<string, Company>>(new Map());
  const [companyRoles, setCompanyRoles] = useState<Map<string, CompanyRoleAssignment[]>>(new Map());
  const [binStatuses, setBinStatuses] = useState<Map<string, BinStatus>>(new Map());

  const [stockLocations, setStockLocations] = useState<Map<string, StockLocation>>(new Map());
  const [filteredStockLocations, setFilteredStockLocations] = useState<Map<string, StockLocation>>(new Map());
  const [stockLocationRoles, setStockLocationRoles] = useState<Map<string, StockLocationRoleAssignment[]>>(new Map());
  const [superUser, setSuperUser] = useState<SuperUser>();
  const [customTranslations, setCustomTranslations] = useState(new Map<string, CustomTranslation>());

  const { loading: userLoading } = useQuery<GetUserResponse>(UserQueries.get, {
    onCompleted: res => {
      setCurrentUser(new User(res.user));
    },
    onError: err => signOut(),
  });

  const { loading: companyRoleAssignmentsLoading } = useQuery<
    GetCompanyRoleAssignmentsResponse,
    GetCompanyRoleAssignmentsVariables
  >(CompanyRoleAssignmentQueries.get, {
    onCompleted: async res => {
      res.companyRoleAssignments.data.forEach(i =>
        companyRoles.set(i.userId, [...(companyRoles.get(i.userId) || []), new CompanyRoleAssignment(i)]),
      );
      setCompanyRoles(new Map(companyRoles));
      if (res.companyRoleAssignments.data.length) {
        const companyId = getCurrentCompanyId() || res.companyRoleAssignments.data[0].companyId;
        storeCurrentCompanyId(companyId);
        getCompany({ variables: { id: companyId } });
      }
    },
    onError: err => signOut(),
  });

  const accessTokenKey = process.env.REACT_APP_ACCESS_TOKEN_STORE || '';
  const refreshTokenKey = process.env.REACT_APP_REFRESH_TOKEN_STORE || '';

  const [getCompany, { loading: companyLoading }] = useLazyQuery<GetCompanyResponse, GetCompanyVariables>(
    CompanyQueries.get,
    {
      onCompleted: async res => {
        if (res.company.settings.featureToggles.company.sessionStorage) {
          localStorage.removeItem(accessTokenKey);
          localStorage.removeItem(refreshTokenKey);
        }

        setCompany(new Company(res.company));
        getCompanies();
        getSuperUser();
        getCompanyRoles({ variables: { companyId: res.company.id } });
        getUsers({ variables: { companyId: res.company.id } });
        getStockLocations({ variables: { companyId: res.company.id } });
        getStockLocationRoles({ variables: { companyId: res.company.id } });
        getBinStatuses({ variables: { companyId: res.company.id } });
        getTranslation();
      },
    },
  );

  const [getTranslation, { loading: translationLoading }] = useLazyQuery<
    GetTranslationResponse,
    GetTranslationVariables
  >(TranslationQueries.get, {
    variables: {
      language: currentUser?.language,
    },
    onCompleted: response => {
      const translation = new Translation(response.translation.language, response.translation.translations);
      setTranslations(new Map([[response.translation.language, translation]]));
      translationsManager.setTranslation(translation, { update: true });
      getCustomTranslations({ variables: { companyId: company.id, page: 0 } });
    },
  });

  const [getCustomTranslations, { loading: customTranslationsLoading }] = useLazyQuery<
    GetCustomTranslationsResponse,
    GetCustomTranslationsVariables
  >(CustomTranslationQueries.get, {
    onCompleted: async response => {
      response.customTranslations.data.forEach(ct => customTranslations.set(ct.id, new CustomTranslation(ct)));
      setCustomTranslations(new Map(customTranslations));
      if (response.customTranslations.hasNext) {
        await getCustomTranslations({ variables: { companyId: company.id, page: response.customTranslations.page++ } });
      } else {
        if (customTranslations.size) {
          const customTranslation = [...customTranslations.values()][0];
          translationsManager.setCustomTranslation(customTranslation, { merge: true });
        }
      }
    },
  });

  const [getCompanies, { loading: companiesLoading }] = useLazyQuery<GetCompaniesResponse, GetCompaniesVariables>(
    CompanyQueries.getAll,
    {
      onCompleted: async res => {
        setCompanies(toMap(res.companies.data, 'id'));
      },
    },
  );

  const [getCompanyRoles, { loading: companyRolesLoading }] = useLazyQuery<
    GetCompanyRoleAssignmentsForCompanyResponse,
    GetCompanyRoleAssignmentsForCompanyVariables
  >(CompanyRoleAssignmentQueries.getForCompany, {
    onCompleted: async res => {
      res.companyRoleAssignmentsForCompany.data.forEach(i => {
        if (i.userId === currentUser?.id && companyRoles.get(currentUser.id)?.find(cRA => cRA.companyId === company.id))
          return;
        companyRoles.set(i.userId, [...(companyRoles.get(i.userId) || []), new CompanyRoleAssignment(i)]);
      });
      setCompanyRoles(new Map(companyRoles));
      if (res.companyRoleAssignmentsForCompany.hasNext === true) {
        await getCompanyRoles({ variables: { companyId: company.id } });
      }
    },
  });

  const [refreshCompanyRolesQuery] = useLazyQuery<
    GetCompanyRoleAssignmentsForCompanyResponse,
    GetCompanyRoleAssignmentsForCompanyVariables
  >(CompanyRoleAssignmentQueries.getForCompany, {
    onCompleted: async res => {
      res.companyRoleAssignmentsForCompany.data.forEach(i => {
        if (i.userId !== currentUser?.userId) {
          companyRoles.set(i.userId, [...(companyRoles.get(i.userId) || []), new CompanyRoleAssignment(i)]);
        }
      });
      setCompanyRoles(new Map(companyRoles));
      if (res.companyRoleAssignmentsForCompany.hasNext === true) {
        await getCompanyRoles({ variables: { companyId: company.id } });
      }
    },
  });

  const [getUsers, { loading: usersLoading }] = useLazyQuery<GetUsersResponse, GetUsersVariables>(
    UserQueries.getForCompany,
    {
      onCompleted: async res => {
        res.users.data.forEach(i => companyUsers.set(i.userId, new User(i)));
        companyUsers.set(
          '00000000-0000-0000-0000-000000000001',
          new User({
            id: '00000000-0000-0000-0000-000000000001',
            email: 'system@ventory.io',
            firstName: 'System',
            lastName: 'Ventory',
          }),
        );

        setCompanyUsers(new Map(companyUsers));
        if (res.users.hasNext === true) {
          await getUsers({ variables: { companyId: company.id, page: ++res.users.page } });
        }
      },
    },
  );

  const [getStockLocations, { loading: stockLocationsLoading }] = useLazyQuery<
    GetStockLocationsResponse,
    GetStockLocationsVariables
  >(StockLocationQueries.get, {
    onCompleted: async res => {
      res.stockLocations.data.forEach(i => stockLocations.set(i.id, new StockLocation(i)));
      setStockLocations(new Map(stockLocations));
      if (res.stockLocations.hasNext === true) {
        await getStockLocations({ variables: { companyId: company.id } });
      } else {
        setFilteredStockLocations(new Map(cloneDeep(stockLocations)));
      }
    },
  });

  const [getStockLocationRoles, { loading: stockLocationRolesLoading }] = useLazyQuery<
    GetStockLocationRoleAssignmentsResponse,
    GetStockLocationRoleAssignmentsVariables
  >(StockLocationRoleAssignmentQueries.get, {
    onCompleted: async res => {
      res.stockLocationRolesForCompany.data.forEach(i =>
        stockLocationRoles.set(i.stockLocationId, [
          ...(stockLocationRoles.get(i.stockLocationId) || []),
          new StockLocationRoleAssignment(i),
        ]),
      );
      setStockLocationRoles(new Map(stockLocationRoles));
      if (res.stockLocationRolesForCompany.hasNext === true) {
        await getStockLocationRoles({ variables: { companyId: company.id } });
      }
    },
  });

  const [refreshStockLocationRolesQuery] = useLazyQuery<
    GetStockLocationRoleAssignmentsResponse,
    GetStockLocationRoleAssignmentsVariables
  >(StockLocationRoleAssignmentQueries.get, {
    onCompleted: async res => {
      res.stockLocationRolesForCompany.data.forEach(i =>
        stockLocationRoles.set(i.stockLocationId, [
          ...(stockLocationRoles.get(i.stockLocationId) || []),
          new StockLocationRoleAssignment(i),
        ]),
      );
      setStockLocationRoles(new Map(stockLocationRoles));
      if (res.stockLocationRolesForCompany.hasNext === true) {
        await getStockLocationRoles({ variables: { companyId: company.id } });
      }
    },
  });

  const [getBinStatuses, { loading: binStatusesLoading }] = useLazyQuery<
    GetBinStatusesResponse,
    GetBinStatusesVariables
  >(BinStatusQueries.get, {
    onCompleted: async res => {
      res.binStatuses.data.forEach(binStatus => binStatuses.set(binStatus.id, new BinStatus(binStatus)));
      setBinStatuses(new Map(binStatuses));
      if (res.binStatuses.hasNext === true) {
        await getBinStatuses({ variables: { companyId: company.id } });
      }
    },
  });

  const [getSuperUser, { loading: superUserLoading }] = useLazyQuery<GetSuperUserResponse, GetSuperUserVariables>(
    SuperUserQueries.get,
    {
      onCompleted: async res => {
        if (res.superUser) setSuperUser(new SuperUser(res.superUser));
      },
    },
  );

  const refreshCompany = async (companyId: string) => {
    reset();
    await getCompany({
      variables: {
        id: companyId,
      },
    });
  };

  const refreshStockLocationRoles = async (companyId: string) => {
    setStockLocationRoles(new Map());
    await refreshStockLocationRolesQuery({ variables: { companyId } });
  };

  const refreshCompanyRoles = async (companyId: string) => {
    setCompanyRoles(new Map());
    await refreshCompanyRolesQuery({ variables: { companyId } });
  };

  const reset = () => {
    setCompanyRoles(new Map());
    setCompanyUsers(new Map());
    setStockLocations(new Map());
    setStockLocationRoles(new Map());
    setBinStatuses(new Map());
  };

  const currentCompanyRole = (companyId: string) => {
    if (!currentUser) return undefined;
    return companyRoles.get(currentUser?.userId || '')?.find(cr => cr.companyId === companyId)?.role;
  };

  const hasCompanyRole = (companyId: string, role: CompanyRole) => {
    if (superUser) return true;
    return currentCompanyRole(companyId) === role;
  };

  const companyRoleAssignmentContext: CompanyRoleAssignmentContextProps = {
    companyRoles,
    setCompanyRoles,
    refreshCompanyRoles,
    currentCompanyRole,
    hasCompanyRole,
  };

  const companyContext: CompanyContextProps = {
    currentCompany: company,
    setCurrentCompany: setCompany,
    companies,
    setCompanies,
    refreshCompany,
  };

  const stockLocationContext: StockLocationContextProps = {
    stockLocations,
    setStockLocations,
    filteredStockLocations, // Maybe get from cookies or cache
    setFilteredStockLocations,
  };

  const currentStockLocationRole = (stockLocationId: string) => {
    if (!currentUser) return undefined;
    return stockLocationRoles.get(stockLocationId)?.find(sr => sr.userId === currentUser.id)?.role;
  };

  const stockLocationRoleContext: StockLocationRoleAssignmentContextProps = {
    stockLocationRoles,
    setStockLocationRoles,
    refreshStockLocationRoles,
    currentStockLocationRole,
    hasStockLocationRole: (
      companyId: string,
      stockLocationId: string,
      role: StockLocationRole,
      userRole?: StockLocationRole,
    ) => {
      if (superUser) return true;
      if (hasCompanyRole(companyId, CompanyRole.administrator)) return true;

      userRole ||= currentStockLocationRole(stockLocationId);
      switch (role) {
        case StockLocationRole.STOCK_LOCATION_VIEWER:
          if (userRole === StockLocationRole.STOCK_LOCATION_VIEWER) return true;
        case StockLocationRole.STOCK_LOCATION_USER:
          if (userRole === StockLocationRole.STOCK_LOCATION_USER) return true;
        case StockLocationRole.STOCK_LOCATION_SUPERVISOR:
          if (userRole === StockLocationRole.STOCK_LOCATION_SUPERVISOR) return true;
        case StockLocationRole.STOCK_LOCATION_MANAGER:
          if (userRole === StockLocationRole.STOCK_LOCATION_MANAGER) return true;
        default:
          return false;
      }
    },
  };

  const binStatusContext: BinStatusContextProps = {
    binStatuses,
    setBinStatuses,
  };

  const superUserContext: SuperUserContextProps = {
    superUser,
    setSuperUser,
  };

  const customTranslationContext: CustomTranslationContextProps = {
    customTranslations,
    setCustomTranslations,
    customTranslationsLoading,
  };

  if (
    userLoading ||
    companyRoleAssignmentsLoading ||
    companyLoading ||
    companiesLoading ||
    companyRolesLoading ||
    usersLoading ||
    stockLocationsLoading ||
    stockLocationRolesLoading ||
    binStatusesLoading ||
    superUserLoading ||
    translationLoading ||
    customTranslationsLoading
  ) {
    return <LoadingScreen />;
  }

  if (!currentUser?.acceptedAgreements || !currentUser?.acceptedPrivacyPolicy || !currentUser?.acceptedTerms) {
    return <AcceptAgreementsScreen />;
  }

  return (
    <CustomTranslationContext.Provider value={customTranslationContext}>
      <CompanyRoleAssignmentContext.Provider value={companyRoleAssignmentContext}>
        <CompanyContext.Provider value={companyContext}>
          <StockLocationContext.Provider value={stockLocationContext}>
            <StockLocationRoleAssignmentContext.Provider value={stockLocationRoleContext}>
              <BinStatusContext.Provider value={binStatusContext}>
                <SuperUserContext.Provider value={superUserContext}>
                  <Routes>
                    <Route
                      path='/admin/*'
                      element={superUser ? <AdministratorScreen /> : <AdministratorNotAllowedScreen />}
                    />
                    <Route path='/company' element={<CompanySelectScreen />} />
                    <Route path='/*' element={company.id ? <ContentPage /> : <OnboardingCompanyPane />} />
                  </Routes>
                </SuperUserContext.Provider>
              </BinStatusContext.Provider>
            </StockLocationRoleAssignmentContext.Provider>
          </StockLocationContext.Provider>
        </CompanyContext.Provider>
      </CompanyRoleAssignmentContext.Provider>
    </CustomTranslationContext.Provider>
  );
}
